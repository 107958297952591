import * as Sentry from '@sentry/vue'
import EmailIcon from '@/assets/img/email.svg'
import { loginWithMagicLink } from '@/services/magicLink'
import { register } from '@/services/register'
import { ERegistrationRole, registration } from '@/store/registration'
import { isError } from '@/types/predicates'
import { ENotification, EOverlayType, postNotification } from '@/utils/notifications'
import { ECypressSubmissionData } from '@/types/api'

export async function submit(): Promise<void> {
  try {
    postNotification(ENotification.showBusyState)
    // setup registration data for sentry logging
    Sentry.setExtra('registration_data', JSON.stringify(registration))

    // capture sentry message if jurisdiction is not in list
    if (registration.isJurisdictionNotInList) {
      Sentry.captureMessage('JurisdictionNotInList', Sentry.Severity.Info)
    }

    await register(registration)

    if (registration.role === ERegistrationRole.host) {
      if (registration.isPreviouslyRegistered === 'true') {
        postNotification(ENotification.showBusyState, {
          type: EOverlayType.message,
          icon: EmailIcon,
          message: 'Thanks for registering with Holo!',
          description:
            "You'll receive an email with steps to complete a USB reset for your HoloPort as well as a link to Quickstart, where you’ll re-connect your HoloPort to the Holo network.",
          cypressData: ECypressSubmissionData.success
        })
      } else {
        postNotification(ENotification.showBusyState, {
          type: EOverlayType.message,
          icon: EmailIcon,
          message: 'Thanks for registering with Holo!',
          description:
            "You'll receive an email with a link to Quickstart, where you'll connect your HoloPorts to the Holo network, and set up your Host Console where you can login and manage the settings of your HoloPorts.",
          cypressData: ECypressSubmissionData.success
        })
      }
    } else if (registration.role === ERegistrationRole.holoFuel) {
      postNotification(ENotification.showBusyState, {
        type: EOverlayType.message,
        icon: EmailIcon,
        message: 'Thanks for registering with Holo!',
        description:
          "You'll receive an email with a one-time registration code for the HoloFuel app, where you’ll create credentials and login. You’ll need to use the same email address you used to register here.",
        cypressData: ECypressSubmissionData.success
      })
    } else if (registration.role === ERegistrationRole.publisher) {
      postNotification(ENotification.showBusyState, {
        type: EOverlayType.message,
        icon: EmailIcon,
        message: 'Thanks for registering with Holo!',
        description:
          'We will review your eligibility for the hApp Manager Alpha Program and if you meet the criteria, you’ll receive an email to join the program.',
        cypressData: ECypressSubmissionData.success
      })
    }
  } catch (e) {
    if (isError(e) && e.message === 'auth_error') {
      postNotification(ENotification.showBusyState, {
        type: EOverlayType.message,
        icon: EmailIcon,
        message: 'Looks like something went wrong',
        description:
          'There is an issue authenticating with the server. The Holo team has been notified. Please try again later.'
      })
      Sentry.captureException(e)
    } else if (isError(e) && e.message === 'already_registered') {
      // Email sent overlay
      postNotification(ENotification.showBusyState, {
        type: EOverlayType.message,
        icon: EmailIcon,
        message: 'This email address is already registered',
        description: 'Check your email for link to access your Holo springboard.',
        cypressData: ECypressSubmissionData.alreadyRegistered
      })

      await loginWithMagicLink(registration.email)
    } else {
      postNotification(ENotification.showBusyState, {
        type: EOverlayType.message,
        icon: EmailIcon,
        message: 'Looks like something went wrong',
        description:
          'There is an issue completing your registration. The Holo team has been notified. Please try again later.'
      })

      Sentry.captureException(e)
    }
  }
}
