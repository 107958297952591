import { Magic } from 'magic-sdk'
import { REGISTRATION_APP_URL } from '@/utils/consts'

export async function loginWithMagicLink(email: string): Promise<void> {
  if (
    process.env.VUE_APP_MAGIC_LINK_KEY === undefined ||
    process.env.VUE_APP_MAGIC_LINK_KEY === ''
  ) {
    console.error('Magic Link API key is missing')
    return
  }

  const magic = new Magic(process.env.VUE_APP_MAGIC_LINK_KEY)

  await magic.user.logout()

  await magic.auth.loginWithMagicLink({
    email,
    showUI: false,
    redirectURI: `${REGISTRATION_APP_URL}/loading`
  })
}
