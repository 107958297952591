import type { Error } from './common'

export function isError(error: Error | unknown): error is Error {
  return error !== undefined
}

export interface InputEventTarget {
  value: string
}

export function isInputEventTarget(
  target: EventTarget | null
): target is InputEventTarget & EventTarget {
  return target !== null && 'value' in target
}

export function isTimeout(timeout: NodeJS.Timeout | unknown): timeout is number {
  return timeout !== undefined
}
