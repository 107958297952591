enum EEnvironment {
  local = 'local',
  development = 'development',
  qa = 'qa',
  production = 'production'
}

const kRegistrationAppURL: Record<string, string> = {
  [EEnvironment.local]: 'https://local-register.holo.host:8080',
  [EEnvironment.development]: 'https://register.dev.holotest.net',
  [EEnvironment.qa]: 'https://register.qa.holotest.net',
  [EEnvironment.production]: 'https://register.holo.host'
}

export const REGISTRATION_APP_URL = process.env.VUE_APP_ENV
  ? kRegistrationAppURL[process.env.VUE_APP_ENV]
  : kRegistrationAppURL.local

const kSpringboardURL: Record<string, string> = {
  [EEnvironment.local]: 'https://local-springboard.holo.host:8081',
  [EEnvironment.development]: 'https://springboard.dev.holotest.net',
  [EEnvironment.qa]: 'https://springboard.qa.holotest.net',
  [EEnvironment.production]: 'https://springboard.holo.host'
}

export const SPRINGBOARD_URL = process.env.VUE_APP_ENV
  ? kSpringboardURL[process.env.VUE_APP_ENV]
  : kSpringboardURL.local

const kRegistrationServiceURL: Record<string, string> = {
  [EEnvironment.local]: 'http://localhost:3001/registration',
  [EEnvironment.development]: 'https://hbs.dev.holotest.net/registration',
  [EEnvironment.qa]: 'https://hbs.qa.holotest.net/registration',
  [EEnvironment.production]: 'https://hbs.holo.host/registration'
}

export const REGISTRATION_SERVICE_URL = process.env.VUE_APP_ENV
  ? kRegistrationServiceURL[process.env.VUE_APP_ENV]
  : kRegistrationServiceURL.local
