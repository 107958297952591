import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import { messages } from './locales'
import router from './router'

import './assets/css/tailwind.css'

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

const app = createApp(App)

if (process.env.VUE_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    app,
    dsn: 'https://e052dee7b2b8409c9457bbdf2bd44732@o1077913.ingest.sentry.io/6081284',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // eslint-disable-next-line require-unicode-regexp
        tracingOrigins: ['localhost', 'my-site-url.com', /^\//]
      })
    ],
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.0
  })
}

app.use(router)
app.use(i18n)
app.mount('#app')
