const translations = {
  $: {
    cancel: 'Cancel',
    confirm: 'Confirm',
    continue: 'Continue',
    close: 'Close',
    error: 'Error',
    errors: {
      email: 'Please enter a valid email.',
      email_does_not_exist:
        'The email you provided does not exist in our database. Please register first.',
      generic_header: 'Oops, looks like we can’t fetch this data right now.',
      invalid_email: 'You have entered an invalid e-mail address. Please try again.',
      try_refreshing: 'Try refreshing the page.',
      unexpected: 'An unexpected error occurred{error}'
    },
    go: 'Go',
    holoport_id: 'HoloPort ID',
    language: {
      en: 'English',
      pl: 'Polski'
    },
    logging_out: 'Logging out...',
    logout: 'Logout',
    looks_good: 'Looks good',
    next: 'Next',
    no: 'No',
    page_not_found: "Hmm... We're unable to find that page.",
    previous: 'Previous',
    roles: {
      host: 'HoloPort',
      publisher: 'hApp Manager',
      holo_fuel: 'HoloFuel User'
    },
    send_link: 'Send link',
    submit: 'Submit',
    try_again: 'Try again',
    version: 'Holo Registration version {version}',
    yes: 'Yes',
    your_email: 'Your email'
  },
  footer: {
    support_forum: 'Support Forum'
  },
  form: {
    holoport_confirmation: {
      errors: {
        format: 'HoloPortID you provided seems to be invalid, please provide different ID.',
        invalid: 'This HoloPort is invalid or does not exist.'
      },
      header: 'Registering a HoloPort',
      ids: "HoloPort ID's",
      paragraph_one:
        'Please confirm the information below is correct. If this information is wrong, contact Holo support at',
      paragraph_two:
        "If these are the only HoloPort's you have registered in the HoloPort Alpha program you’re all set to continue. If you have additional HoloPorts registered in the HoloPort Alpha Program not listed here, please add them now.",
      what_is_your_holoport_id: 'What is your HoloPort ID?'
    },
    holoport_registration: {
      errors: {
        format: 'HoloPortID you provided seems to be invalid, please provide different ID.',
        invalid: 'This HoloPort does not exist in our database.'
      },
      header: 'Registering a HoloPort',
      question_one: 'Are you an active participant in the HoloPort Alpha Program?',
      question_two: 'What is your HoloPort ID?'
    },
    initial_step: {
      first_time_question: 'Is this your first time registering with Holo?',
      first_time_question_affirmative: "Yes, it's my first time registering",
      first_time_question_negative: "No, I've registered before",
      header: 'Register to participate in the Holo network'
    },
    legal_jurisdiction: {
      country_question: 'What country do you live in?',
      errors: {
        empty: 'Please select a country.',
        input: {
          empty: 'Please provide your country.',
          invalid: 'Invalid country, please avoid using special characters.'
        }
      },
      input_placeholder: 'Your country',
      select_placeholder: 'Select a country'
    },
    register: {
      email: {
        label: 'What is your email address?',
        placeholder: 'Your email',
        errors: {
          empty: 'Please provide your email.',
          invalid: 'It looks like the email you provided is invalid.'
        }
      },
      first_name: {
        label: 'What is/are your legal given name(s)?',
        placeholder: 'First name',
        errors: {
          empty: 'Please provide your first name.'
        }
      },
      host: {
        question: 'Have you previously connected your HoloPort to the Holo network?',
        errors: {
          empty: 'Please select one of the options above.'
        }
      },
      role: {
        question: 'Are you registering a HoloPort, as a hApp Manager, or HoloFuel user?',
        select_placeholder: 'Select a role',
        errors: {
          empty: 'Please select your role.'
        }
      },
      surname: {
        label: 'What is your legal surname?',
        placeholder: 'Last name',
        errors: {
          empty: 'Please provide your last name.'
        }
      },
      terms_of_service: {
        checkbox_label_part_one: 'Check this box to accept the',
        checkbox_label_part_two: 'Alpha Terms of Service',
        errors: {
          empty: 'Please accept the Terms of Service.'
        }
      }
    }
  },
  login: {
    check_you_email: 'Check your email to access Springboard',
    email_label: 'Enter your email to get a access link to your Holo springboard',
    email_question: 'What is your email address?',
    errors: {
      email_does_not_exist:
        'The email you provided does not exist in our database. Please register first.',
      email_invalid: 'The email you provided seems not to be valid. Please provide a valid email.'
    },
    header: 'Enter your email to access your Holo springboard',
    link: 'registering with Holo',
    link_prefix: 'First time? Start by'
  },
  sidebar: {
    registration: {
      description:
        'You can register a HoloPort, as a hApp Manager in the Alpha Test program, or as a HoloFuel user. ',
      description_two:
        'To learn more about the different ways you can participate in the Holo network,',
      link: 'check out our FAQ’s',
      title: 'Holo Registration:'
    },
    holoport: {
      participant: {
        title: 'Where to find your HoloPort ID',
        description: 'Your HoloPort ID can be found on your Host Console URL.',
        section_two: {
          message:
            'If you have not bookmarked the URL, look for your registration email, with the subject: “Your HoloPort Registration!” The link with the URL is in there in blue under the button.',
          link: 'Learn More. '
        }
      },
      non_participant: {
        title: 'What to expect:',
        description:
          'After completing registration with Holo, you’ll receive an email with a link to Quickstart, where you’ll connect your HoloPort to the Holo network.',
        section_two: {
          title: 'What you’ll need:',
          point_one: 'Email address',
          point_two: 'HoloPort ID’s for any that you have registered in the',
          point_two_link: 'HoloPort Alpha Program'
        }
      }
    }
  },
  terms_of_service: {
    checkbox_label: 'Check this box to accept the',
    link: 'Alpha Terms of Service',
    message: 'Submit your registration to see if you’re eligible for the hApp Manager Alpha Program.',
    registered_message:
      'We will review your eligibility for the hApp Manager Alpha Program and if you meet the criteria, you’ll receive an email to join the program.',
    registered_title: 'Thanks for registering as a hApp Manager!',
    title: 'Terms of Service'
  }
} as const

export default translations
