export enum EStatus {
  unregistered = 'unregistered',
  new = 'new',
  sent = 'sent',
  registered = 'registered'
}

export enum ERegistrationRole {
  host = 'host',
  publisher = 'publisher',
  user = 'user'
}

export enum ECypressSubmissionData {
  success = 'SUCCESS',
  alreadyRegistered = 'ALREADY_REGISTERED'
}

export interface UserData {
  jurisdiction: string
  legalName: string
  primaryEmail: string
  status: EStatus
}

export interface RegisterPublisherResponse {
  email: string
  status: EStatus
}

interface AgentPubKey {
  role: string
  pubKey: string
}

export interface RegistrationCode {
  agentPubKeys: AgentPubKey[]
  code: string
  role: ERegistrationRole
}

export interface RegistrationCodeWithStatus {
  agentPubKeys: AgentPubKey[]
  code: string
  role: ERegistrationRole
  status: string
}
