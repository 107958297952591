import { reactive, ref } from 'vue'

/* eslint-disable @typescript-eslint/naming-convention */
export enum ERegistrationRole {
  none = '',
  host = 'host',
  publisher = 'publisher',
  holoFuel = 'holofuel'
}
/* eslint-enable @typescript-eslint/naming-convention */

export enum ERegistrationStep {
  initial = 0,
  holoPortRegistration = 1,
  holoPortConfirmation = 2,
  submission = 3
}

export interface Registration {
  currentRegistrationStep: ERegistrationStep
  givenNames: string
  lastName: string
  email: string
  isJurisdictionNotInList: boolean
  jurisdiction: string
  role: ERegistrationRole
  acceptedTermsOfService: boolean
  participantInHoloPortProgram: boolean
  holoportIds: string[]
  isPreviouslyRegistered: string
}

export const hasSelectedOption = ref(false)
export const firstTimeRegistering = ref(false)

export const registration = reactive<Registration>({
  currentRegistrationStep: ERegistrationStep.initial,
  givenNames: '',
  lastName: '',
  email: '',
  isJurisdictionNotInList: false,
  jurisdiction: '',
  role: ERegistrationRole.none,
  acceptedTermsOfService: false,
  participantInHoloPortProgram: false,
  holoportIds: [],
  isPreviouslyRegistered: ''
})
