import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoadingPage from '@/pages/LoadingPage.vue'
import NotFound from '@/pages/NotFound.vue'
import RegistrationView from '@/pages/RegistrationView.vue'

interface Routes {
  home: RouteRecordRaw
  holo: RouteRecordRaw
  error: RouteRecordRaw
  loading: RouteRecordRaw
  catch: RouteRecordRaw
}

export const kRoutes: Routes = {
  home: {
    path: '/',
    name: 'Registration',
    component: RegistrationView
  },
  holo: {
    path: '/holo',
    name: 'Holo',
    component: RegistrationView
  },
  loading: {
    path: '/loading',
    name: 'Loading',
    component: LoadingPage
  },
  error: {
    path: '/404',
    name: 'Error',
    component: NotFound
  },
  catch: {
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    name: 'Catch',
    redirect: '/404'
  }
} as const

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [kRoutes.home, kRoutes.holo, kRoutes.error, kRoutes.loading, kRoutes.catch]
})

export default router
