import type {
  ShowBusyStateNotificationObject,
  ShowBannerNotificationObject
} from '@/utils/notifications'

export enum ESpinnerSize {
  none,
  small,
  medium,
  large,
  xl
}

export enum EInputType {
  text = 'text',
  email = 'email',
  url = 'url',
  password = 'password',
  number = 'number',
  tel = 'tel'
}

export enum EButtonType {
  none = -1,
  primary,
  secondary,
  tertiary
}

export enum EButtonStyle {
  normal,
  warning,
  danger
}

export enum EAlertType {
  normal,
  warning,
  danger
}

export type AlertCallback = (button: EButtonType) => void

export interface OverlayExports {
  show: (object?: ShowBusyStateNotificationObject) => void
  hide: () => void
}

export interface BannerExports {
  show: (object?: ShowBannerNotificationObject) => void
  hide: () => void
}

export enum EArrowSize {
  small = 'small',
  medium = 'medium'
}
