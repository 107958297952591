import axios, { AxiosError, AxiosResponse } from 'axios'
import type { Registration } from '@/store/registration'
import { isError } from '@/types/predicates'
import { REGISTRATION_SERVICE_URL } from '@/utils/consts'

enum EHttpStatus {
  conflict = 409,
  ok = 200
}

export class RegistrationError extends Error {
  response: AxiosResponse | AxiosError
  constructor(message: string, response: AxiosResponse | AxiosError) {
    super(message)
    this.response = response
  }
}

export async function register(registration: Registration): Promise<void> {
  try {
    await axios({
      method: 'post',
      url: `${REGISTRATION_SERVICE_URL}/api/v1/register`,
      data: {
        givenNames: registration.givenNames,
        lastName: registration.lastName,
        email: registration.email,
        isJurisdictionNotInList: registration.isJurisdictionNotInList,
        jurisdiction: registration.jurisdiction,
        role: registration.role,
        acceptedTermsOfService: registration.acceptedTermsOfService,
        participantInHoloPortProgram: registration.participantInHoloPortProgram,
        holoportIds: registration.holoportIds,
        isPreviouslyRegistered: registration.isPreviouslyRegistered === 'true'
      }
    })
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // check if the email is already registered
      if (e.response?.status === EHttpStatus.conflict) {
        throw new RegistrationError('already_registered', e)
      } else {
        // check for any other issues
        throw new RegistrationError(e.message, e)
      }
    } else if (isError(e)) {
      throw e
    }
  }
}

export async function verifyHoloport(holoportId: string): Promise<boolean> {
  try {
    // submit registration request
    const result = await axios({
      method: 'get',
      url: `${REGISTRATION_SERVICE_URL}/api/v1/verify-holoport/${holoportId}`
    })

    if (result.status === EHttpStatus.ok) {
      return true
    }
  } catch (e) {
    return false
  }

  return false
}

export async function verifyEmail(email: string): Promise<boolean> {
  try {
    await axios({
      method: 'get',
      url: `${REGISTRATION_SERVICE_URL}/api/v1/verify-email/${email}`
    })

    return true
  } catch (e) {
    return false
  }
}
