<template>
  <p>
    {{ $t('sidebar.registration.description') }}
  </p>
  <p class="mt-6">
    {{ $t('sidebar.registration.description_two') }}
    <a
      href="https://holo.host/faq-tag/registration-quickstart/"
      target="_blank"
      class="text-white font-semibold visited:text-white"
    >
      {{ $t('sidebar.registration.link') }}
    </a>.
  </p>
</template>

<script lang="ts"></script>
